/* Variables start */
:root {
  --color-black: #000;
    --color-black-light: #1b1919;
  --color-white: #fff;
  --color-gunmetal: #383945;
    --color-gunmetal-dark: #2d2d2f;
  --color-gold-one: #c7c5b3;
  --color-gold-two: #9a8866;
  --color-blue-light: #0292c0;
  --color-gray: #424242;
    --color-gray-light : #636363;
  --color-green: #00a700;
    --color-green-light: #6a9a66;
  --color-orange : #ca8a00;
  --color-red: #cd1e2f;
    --color-red-light: #9a6666;
  --color-purple: #8c6edf;
  --color-yellow: #ffe084;
    --color-yellow-light: #E0DFD6;
}


.desc-line-break {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50%;
}

body {
    background-color: var(--color-gunmetal-dark);
    background-image: url('images/background-1.png');
    color: var(--color-gold-one);
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: bender, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body * {
    box-sizing: border-box;
}

iframe {
    border: 0;
    height: 100%;
    width: 100%;
}

input,
select,
button {
    border-radius: 0;
    font-family: inherit;
}

button,
input[type='submit'] {
    background-color: var(--color-gold-two);
    border: 0;
    color: var(--color-black);
    height: 40px;
    padding: 0;
}

input[type='text'],
input[type='number'] {
    padding: 12px;
    max-height: 40px;
    border: 2px solid var(--color-gold-two);
    background-color: var(--color-gunmetal-dark);
    color: var(--color-gold-one);
}

input[type='text']:focus,
input[type='number']:focus {
    outline: none;
    border: 2px solid var(--color-gold-one);
}

input[type='text'].number {
    width: 80px;
}

input[name='session-id'] {
    padding-left: 20px;
}

select {
    padding: 10px;
    margin-bottom: 2vh;
}

a {
    color: var(--color-gold-two);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

cite {
    display: block;
    font-size: 14px;
}

.display-wrapper {
    height: var(--display-height);
    min-height: 80vh;
    margin: 0 10px;
    position: relative;
}

.page-wrapper {
    margin: 0 10px;
    max-width: 1200px;
    /*min-height: 80vh;*/
}

.updated-label {
    color: var(--color-gold-one);
    font-size: 10px;
    left: 4px;
    position: absolute;
    top: 2px;
}

.time-wrapper {
    background-color: rgb( from var(--color-black) r g b / 0.5);
    padding: 10px 20px;
    position: absolute;
    right: -10px;
    top: 0;
    z-index: 1005;
    text-align: right;
}

.time-wrapper a {
    color: inherit;
}

.time-wrapper div {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.map-image-wrapper {
    height: var(--display-height);
    width: 98vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.icon-with-text {
    vertical-align: middle;
    margin-right: 8px;
    margin-left: 8px;
}

.icon-with-text-hidden {
    visibility: hidden;
}

.center-title {
    text-align: center;
}

.screen-link {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen-link-icon {
    vertical-align: middle;
    margin-right: 8px;
}

.price-wrapper {
    color: var(--color-gold-one);
    font-size: 14px;
}

.price-wrapper-tool {
    color: var(--color-blue-light);
    font-size: 14px;
}

.page-headline-wrapper {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    white-space: nowrap;
}

.page-headline-wrapper h1 {
    flex-grow: 1;
    text-align: center;
    white-space: initial;
    display: inline-flex;
}

.wiki-link-wrapper {
    font-size: larger;
}

div.tippy-box {
    background-color: var(--color-gunmetal-dark);
    color: var(--color-gold-one);
    border-color: var(--color-gray);
    border-radius: 4px;
    border-style: solid;
    border-width: 3px;
    box-shadow: 0 0 0 1px rgb( from var(--color-black) r g b / 0.1);
}

div.tippy-box[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: var(--color-gray);
    bottom: -10px;
}

div.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: var(--color-gray);
    top: -10px;
}

div.tippy-box[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: var(--color-gray);
    right: -10px;
}

div.tippy-box[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: var(--color-gray);
    left: -10px;
}

.hr-muted {
    margin-top: 2rem;
    border-bottom: 1px solid var(--color-gold-two);
    width: 80%;
    opacity: 0.4;
}

.hr-muted-full {
    margin-top: 2rem;
    border-bottom: 1px solid var(--color-gold-two);
    width: 100%;
    opacity: 0.4;
}

.rc-slider-mark-text {
    color: rgb( from var(--color-gold-one) r g b / 0.8) !important;
}

.rc-slider-mark-text-active {
    color: rgb( from var(--color-gold-one) r g b/ 1) !important;
}

/* Global reuseable styles, specific to the Tarkov.Dev style */
.information-section {
    background: rgb( from var(--color-black) r g b / 0.1);
    border: 1px solid rgb( from var(--color-white) r g b/ 0.1);
    border-radius: 0 20px 0 20px;
    margin-bottom: 50px;
    overflow: hidden;
}
.information-section.has-table {
    border-radius: 0 20px 0 0;
}

.information-section h2 {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin: 0;
    padding: 15px 20px;
    background: rgb( from var(--primary-color-black) r g b / 0.4);
    color: var(--color-gold-one);
    background: rgb( from var(--color-black) r g b / 0.3);
    border-bottom: 1px solid rgb( from var(--color-white) r g b / 0.1);
}
.information-section h2 svg {
    width: 1.6rem !important;
    height: auto !important;
    margin: 0 12px 0 0;
}
.information-section .content {
  padding: 20px;
}
.information-section .content p {
  margin: 0;
}

.filter-wrapper.open {
    z-index: 2;
}
















@media screen and (min-width: 800px) {
    .control-wrapper {
        display: none;
    }

    .page-headline-wrapper h1 {
        flex-grow: 1;
        text-align: left;
    }
}

@media screen and (min-width: 1280px) {
    .page-wrapper {
        margin: 0 auto;
    }
}

@media screen and (min-width: 1920px) {
    .page-wrapper {
        margin: 0 auto;
        max-width: 1600px;
    }
}

@import './styles/singleEntity.css'