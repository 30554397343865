/* Main page wrapper */
.entity-page-wrapper {
  margin: 30px auto 100px auto;
  max-width: 1200px;
  position: relative;
}

/* Top Section */
.entity-information-wrapper {
  display: flex;
  background-color: rgb( from var(--color-black) r g b /  0.1);
  border: 1px solid rgb( from var(--color-gold-two) r g b /  0.7);
  margin-bottom: 30px;
  box-shadow: 0 0 5px 3px rgb( from var(--color-white) r g b / 0.034);
  overflow: hidden;
  min-height: 260px;
}

.entity-icon-cont {
  border-left: 1px solid var(--color-gold-two);
  background: var(--color-gunmetal-dark);
  display: flex;
  min-width: 350px;
  height: inherit;
  align-items: center;
  justify-content: center;
  position: relative;
}

.entity-icon-and-link-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.entity-top-content {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  max-width: 850px;
}
.entity-top-content .title-bar {
  display: flex;
  align-items: center;
  background: rgb( from var(--color-black) r g b / 0.4);
  padding: 10px 20px;
  border-bottom: 1px solid rgb( from var(--color-white) r g b / 0.2);
}
.entity-top-content .entity-information-icon {
  display: none;
  width: 124px;
  margin: 20px auto;
  height: 124px;
  border: 1px solid rgb( from var(--color-white) r g b / 0.5);
}
.entity-top-content .title-bar h1 {
  display: inline-block;
  color: var(--color-gold-two);
  align-items: center;
  justify-content: space-between;
  cursor: default;
  margin: 0;
}
.entity-top-content .title-bar .type {
  opacity: .8;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: auto 12px auto 0;
  border: 1px solid var(--color-white);
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  color: #fff;
  cursor: default;
}
.entity-top-content .title-bar .wiki-link-wrapper {
  margin: auto 0 auto auto;
  opacity: 0.9;
  transition-delay: .3s;
}
.entity-top-content .title-bar .wiki-link-wrapper:hover {
  opacity: 1;
  transition: all .1s;
}
.entity-top-content .main-content {
  padding: 20px 20px 0 20px;
}
.entity-top-content .entity-properties {
  padding: 0 20px;
  margin-top: auto;
  margin-bottom: 0;
}
.entity-top-content .main-content p.entity-details {
  margin: 0 0 20px 0;
}
.entity-top-content .main-content p.entity-details h3 {
  margin-top: 0;
}


@media screen and (max-width: 960px){
  .entity-information-wrapper {
    min-height: none;
  }
  .entity-icon-cont {
     display: none;
  }
  .entity-top-content .entity-information-icon {
    display: inline-block;
  }
}

@media screen and (max-width: 600px){
  .entity-top-content .title-bar {
    flex-direction: column;
  }
  .entity-top-content .title-bar .type {
    margin: 0 auto 10px auto;
  }
  .entity-top-content .title-bar .wiki-link-wrapper {
    margin: 10px auto;
  }
}