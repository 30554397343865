.id-wrapper {
    background-color: rgb( from var(--color-black) r g b / 0.5);
    bottom: 0;
    color: #fff;
    display: none;
    font-family: bender;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 40px;
    position: fixed;
    z-index: 405;
}

.id-wrapper-left {
    left: 0;
}
.id-wrapper-right {
    right: 0;
}

.session-question {
    /* background-color: black; */
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0px;
    width: 30px;
}

.session-question:hover .session-popup {
    display: block;
}

.session-popup {
    display: none;
    position: absolute;
    bottom: 0;
    width: 30vw;
    background-color: rgb( from var(--color-black) r g b / 0.8);
    padding: 15px;
    font-size: 16px;
}

.id-wrapper-left .session-popup {
    left: 0;
}

.id-wrapper-right .session-popup {
    right: 0;
}

.session-switch-side {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 16px;
    font-weight: bold;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-white);
}

.session-switch-side:hover,
.session-switch-side:focus,
.session-switch-side:active {
    text-decoration: none;
}

.id-wrapper svg {
    fill: var(--color-gold-one);
    height: 35px;
    margin-right: 1vh;
    width: 35px;
}

.session-id {
    display: inline-block;
    line-height: 23px;
    position: relative;
    top: -6px;
}

.update-label {
    font-size: 10px;
    text-align: center;
    color: var(--color-white);
}

@media screen and (min-width: 800px) {
    .id-wrapper {
        display: block;
    }
}
